:root {
  --primary-color: #5669ff;
  --secondary-color: #201a3c;
  --warning-color: #f44336;
}

$primary-palette: (
  50: #eaebff,
  100: #c9cbff,
  200: #a3aaff,
  300: #7987ff,
  400: var(--primary-color),
  500: #2f4cfa,
  600: #2a43ee,
  700: #1d37e1,
  800: #0c2ad6,
  900: #020cbd,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #d6d9ff,
  A700 : #bdc1ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$secondary-palette: (
  50: #e6e7ed,
  100: #c1c3d4,
  200: #9a9db6,
  300: #757799,
  400: #5b5c85,
  500: #434172,
  600: #3d3a6a,
  700: #353260,
  800: #2d2953,
  900: var(--secondary-color),
  A100 : #765AFF,
  A200 : #4B27FF,
  A400 : #2900F3,
  A700 : #2400DA,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  ),
);

// $warn-palette: (
//   50: #fcedee,
//   100: #f8d3d2,
//   200: #e4a59d,
//   300: #d68578,
//   400: #dd6e56,
//   500: #e2643c,
//   600: #d45b3b,
//   700: #c35235,
//   800: #b64b2f,
//   900: #a84224,
//   A100 : #FF8A80,
//   A200 : #FF5252,
//   A400 : #FF1744,
//   A700 : #D50000,
//   contrast: (
//     50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #ffffff,
//     A700 : #ffffff,
//   ),
// );
